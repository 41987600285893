<template>
  <v-app id="inspire">
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-img src="@/assets/img/logo_color.png" class="mb-4" />

            <v-alert
              v-model="notification.show"
              type="error"
              transition="scale-transition"
              dismissible
            >
              {{ notification.message }}
            </v-alert>

            <Form @submit="login">
              <v-card class="elevation-12">
                <v-toolbar
                  color="primary"
                  dark
                  flat
                >
                  <v-toolbar-title>Iniciar sesión</v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                  <TextField
                    v-model="form.email"
                    label="Correo electrónico"
                    prepend-icon="mdi-account"
                    rules="required"
                  />

                  <TextField
                    v-model="form.password"
                    label="Contraseña"
                    prepend-icon="mdi-lock"
                    rules="required"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                  />
                </v-card-text>

                <v-card-actions class="d-flex flex-column justify-center">
                  <v-btn
                    class="px-10 mb-3"
                    color="primary"
                    type="submit"
                  >
                    <v-icon left>mdi-login</v-icon>
                    Entrar
                  </v-btn>

                  <router-link :to="{ name: 'signup' }" class="my-3">
                    No tengo cuenta
                  </router-link>

                  <router-link :to="{ name: 'password-recovery-email' }">
                    Olvidé mi contraseña
                  </router-link>
                </v-card-actions>
              </v-card>
            </Form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// API
import AuthAPI from '@/api/auth';

export default {
  name: 'Login',

  data: () => ({
    form: {
      email: null,
      password: null,
    },
    notification: {
      show: false,
      message: null,
    },
    showPassword: false,
  }),

  created() {
    this.api = new AuthAPI();
  },

  methods: {
    async login() {
      this.$store.commit('showLoader');
      try {
        const tokens = await this.api.login(this.form);
        this.$store.dispatch('auth/setTokens', tokens);
        const profile = await this.api.profile();
        this.$store.commit('auth/setProfile', profile);
        this.$router.push('/');
      } catch (error) {
        switch (error.status) {
          case 400:
          case 401:
            this.notification.message = 'Credenciales incorrectas.';
            break;

          default:
            this.notification.message = 'Ocurrió un error. Por favor, vuelve a intentar.';
        }

        this.notification.show = true;
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
