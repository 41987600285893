<template>
  <!-- eslint-disable max-len -->
  <v-app>
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          lg="4"
        >
          <v-img src="@/assets/img/logo_color.png" />
        </v-col>

        <v-col cols="12">
          <h1 class="text-center"> WAPIDU - AVISO DE PRIVACIDAD </h1>
          <br>

          <h3> IDENTIDAD Y DOMICILIO DEL RESPONSABLE. </h3>
          <p>
            En WAPIDU, S.A. DE C.V., (en adelante WAPIDU) con domicilio en Félix Cuevas # 6, Colonia Tlacoquemécatl del Valle, Delegación
            Benito Juárez, Código Postal 03200 en la Ciudad de México, los datos personales y personales
            sensibles, de nuestros consumidores y posibles consumidores (en adelante “datos personales”),
            son tratados de forma estrictamente privada y confidencial, por lo que la obtención,
            tratamiento, transferencia y ejercicio de los derechos derivados de dichos datos personales,
            se hace mediante un uso adecuado, legítimo y lícito, salvaguardando permanentemente los
            principios de licitud, consentimiento, calidad, información, proporcionalidad,
            responsabilidad, lealtad y finalidad, de conformidad con lo dispuesto por la Ley Federal de
            Protección de Datos Personales en Posesión de los Particulares, su Reglamento y disposiciones
            secundarias.
          </p>
          <br>

          <h3> USO DE COOKIES, WEB BEACONS U OTRAS TECNOLOGÍAS </h3>
          <p>
            WAPIDU le informa que, podrá utilizar mecanismos en medios remotos o locales de comunicación
            electrónica, óptica u otra tecnología; tales como cookies y web beacons, que permitan recabar
            diversos datos de manera automática y simultánea al tiempo que usted como consumidor o posible
            consumidor hace contacto con los mismos. Si quieres tener más información, por favor, consulta
            nuestra Política de Privacidad, donde podrás revisar cómo desactivarlos y en su caso, qué
            cookies estamos usando para recabar tus datos personales.
          </p>
          <br>

          <p>
            Los cookies son aquellos archivos de datos que se almacenan en el disco duro del equipo de
            cómputo o del dispositivo de comunicaciones electrónicas de un usuario al navegar en un sitio
            de Internet específico, el cual permite intercambiar información de estado entre dicho sitio y
            el navegador del usuario. La información de estado puede revelar en algunos casos medios de
            identificación de sesión, autenticación o preferencias del usuario, así como cualquier otro
            dato almacenado por el navegador respecto del sitio de Internet.
          </p>
          <br>

          <p>
            Se entiende por web beacons a aquella imagen visible u oculta insertada dentro de un sitio web
            o correo electrónico, que se utiliza para monitorear el comportamiento del usuario en estos
            medios. A través de éstos se puede obtener información como la dirección IP de origen,
            navegador utilizado, sistema operativo, momento en que se accedió a la página, y en el caso
            del correo electrónico, la asociación de los datos anteriores con el destinatario.
          </p>
          <br>

          <p>
            En concreto, WAPIDU, utilizará cookies y web beacons al enviar correos electrónicos con
            información relacionada con la Marca y el resto de las marcas que distribuye y comercializa
            WAPIDU, ya que al momento en que usted reciba y, en su caso, lea alguno de nuestros correos,
            por medio de estas tecnologías podremos recabar los siguientes datos:
            <br><br>
            <ul>
              <li>Conocer si abrió o no el correo electrónico que le enviamos y;</li>
              <li>Conocer si accedió o no a la(s) liga(s) o URL(s) contenidas en el correo.</li>
            </ul>
          </p>
          <br>

          <p>
            Asimismo, le informamos que los datos que se obtienen a través de estas tecnologías se
            utilizarán exclusivamente para fines estadísticos y de revisión de resultados, sin que exista
            la posibilidad de transferirlos a terceros.
          </p>
          <br>

          <p>
            La mayoría de los exploradores acepta las cookies automáticamente. Aun así, es posible
            configurar cualquier explorador para evitar que acepte cookies e impedir de este modo que su
            equipo o dispositivo las almacene. Algunos exploradores cuentan con un modo especial que
            garantiza la eliminación de las cookies tras una visita. Consulte el manual de su explorador
            si desea conocer los pasos exactos que permiten activar el modo correspondiente. Puede
            eliminar las cookies que se encuentren almacenadas en su equipo o dispositivo en cualquier
            momento.
          </p>
          <br>

          <p>
            En este sentido, en apoyo a usted y para ejemplificarlo establecemos la forma en que se pueden
            deshabilitar las cookies en el navegador Internet Explorer y que son las siguientes: i) Abrir
            en su computadora el navegador Internet Explorer. ii) Al ingresar hacer click en el botón
            Herramientas y después en Opciones de Internet y iii) Posteriormente hacer click en la ficha
            Privacidad, y a continuación, en Configuración, mueva el control deslizante totalmente hacia
            arriba para bloquear todas las cookies o totalmente hacia abajo para permitir todas las
            cookies y, a continuación, haga clic en Aceptar.
          </p>
          <br>

          <p>
            En ningún otro caso, WAPIDU utilizará este tipo de tecnologías. En caso contrario, lo hará de
            su conocimiento previamente.
          </p>
          <br>

          <h3> MODIFICACIONES Y CAMBIOS AL AVISO DE PRIVACIDAD </h3>
          <p>
            Este aviso de privacidad podrá ser modificado o actualizado de tiempo en tiempo por WAPIDU.
            Dichas modificaciones serán informadas a través de una publicación en nuestro portal de
            internet posteriormente a su modificación.
          </p>
          <br>

          <p>
            <small>
              Fecha de Publicación: 23 de septiembre de 2015
              <br>
              Fecha de última actualización: octubre de 2019
            </small>
          </p>
        </v-col>

        <v-col cols="12" md="6" lg="4">
          <v-btn
            color="primary"
            block
            @click="close"
          >
            <v-icon left>mdi-close</v-icon>
            Cerrar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'NoticeOfPrivacy',

  methods: {
    close() {
      window.close();
    },
  },
};
</script>

<style lang="scss" scoped></style>
